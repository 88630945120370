import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {IResponse} from "../../models/IResponse";
import Presence from "./Presence";
import Desire from "./Desire";
import Car from "./Car";

interface IFanfaronResponseProps {
    response: IResponse
}

const FanfaronResponse = (props: IFanfaronResponseProps) => {

    const {response} = props;

    return <>
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>

            <TableCell>
                {response.fanfaron.nickname}
            </TableCell>

            <TableCell>
                <Presence response={response}/>
            </TableCell>

            <TableCell>
                <Desire response={response}/>
            </TableCell>

            {response.outwardCar &&
                <TableCell>
                    <Car car={response.outwardCar}/>
                </TableCell>
            }

            {response.inwardCar &&
                <TableCell>
                    <Car car={response.inwardCar}/>
                </TableCell>
            }

            <TableCell>
                {response.fanfaron.musicalInstrument}
            </TableCell>

        </TableRow>
    </>
}

export default FanfaronResponse;