import React from 'react';
import {Button as MuiButton, ButtonProps, styled} from '@mui/material';

const ColorButton = styled(MuiButton)<ButtonProps>(() => ({
    backgroundColor: '#bb4430',
    '&:hover': {
        backgroundColor: '#7c1603',
    },
}));

const PressedColorButton = styled(MuiButton)<ButtonProps>(() => ({
    backgroundColor: '#7c1603',
    '&:hover': {
        backgroundColor: '#bb4430',
    },
}));

export interface IButtonProps {
    icon?: any;
    label: string;
    onClick: any;
    display?: boolean;
    press?: boolean;
}

const Button = (props: IButtonProps) => {

    const {icon, label, onClick, display, press} = props;

    return <>
        {display && !press &&
            <ColorButton variant="contained" sx={{margin: '4px', padding: '4px 12px'}}
                         onClick={onClick}>
                {icon} <span style={{marginLeft: '4px'}}>{label}</span>
            </ColorButton>
        }
        {display && press &&
            <PressedColorButton variant="contained" sx={{margin: '4px', padding: '4px 12px'}}
                                onClick={onClick}>
                {icon} <span style={{marginLeft: '4px'}}>{label}</span>
            </PressedColorButton>
        }
    </>
}

export default Button;