import {IFanfaron} from "../models/IFanfaron";
import {IApiUser} from "../models/api/IApiUser";
import {callUsers} from "./ApiService";

export let fanfaronRepository: IFanfaron[] = [];


export const fetchFanfarons = (): Promise<IFanfaron[]> => {
    if (fanfaronRepository.length !== 0) return new Promise(resolve => resolve(fanfaronRepository));
    return new Promise(resolve =>
        callUsers().then((users: IApiUser[]) => {
            fanfaronRepository = users.map((user: any) => adaptApiUserToFanfaron(user));
            resolve(fanfaronRepository);
        }));
}

const adaptApiUserToFanfaron = (user: IApiUser): IFanfaron => {
    return {
        id: user.id.toString(),
        order: 0,
        nickname: user.nickname,
        firstName: user.name,
        lastName: user.surname,
        musicalInstrument: user.instrument.label,
        old: false,
        emailAddress: user.email
    }
}


export const fetchFanfaronByFanfaronId = (fanfaronId?: string): IFanfaron => {

    const filteredFanfarons = fanfaronRepository.filter(fanfaron => fanfaron.id === fanfaronId);
    return filteredFanfarons.length > 0 ? filteredFanfarons[0] : {} as IFanfaron;
}

export const fetchFanfaronByUsername = (username?: string): IFanfaron => {

    const filteredFanfarons = fanfaronRepository.filter(fanfaron => fanfaron.username === username);
    return filteredFanfarons.length > 0 ? filteredFanfarons[0] : {} as IFanfaron;
}