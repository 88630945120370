import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Toolbar, Typography, IconButton, Avatar} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import TerminusLogo from "../../../assets/images/logo.jpg";
import {getUser} from "../../services/UserService";
import {removeToken} from "../../services/AuthenticationService";

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }));

interface IMyAppBarProps {
    setIsAuthenticated: any;
}
const MyAppBar = (props: IMyAppBarProps) => {

    const [username, setUsername] = useState('');
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        const user = getUser();
        setUsername(user.given_name);
        setAvatar(user.picture);
    }, []);

    const signOut = () => {
        removeToken();
        props.setIsAuthenticated(false);
    }

    return <>
        <AppBar position="absolute" sx={{backgroundColor: '#bb4430'}}>
            <Toolbar sx={{pr: '24px'}}>

                <Box sx={{height: 64, marginRight: '30px'}}>
                    <img src={TerminusLogo} alt="Logo du Terminus Brass Band"
                         style={{height: '100%'}}/>
                </Box>

                <Typography fontSize={18} color={'#ffffff'} sx={{flexGrow: 1}}>
                    Espace Admin du Terminus Brass Band
                </Typography>

                <Typography fontSize={16} color={'#ffffff'} sx={{marginRight: '20px'}}>
                    hello {username}
                </Typography>

                <Box>
                    <Avatar alt="Avatar" src={avatar}/>
                </Box>

                <IconButton size="large" aria-label="Déconnexion" color="inherit"
                            onClick={signOut}>
                    <ExitToAppIcon/>
                </IconButton>

            </Toolbar>
        </AppBar>
    </>
}

export default MyAppBar;