import React, {useState} from 'react';
import {Box, Chip} from '@mui/material';

import {IPresence, IResponse} from "../../models/IResponse";
import {updateGraphQLResponse} from "../../../graphql/GraphQLService";

const chips = [
    {label: "👍", presence: IPresence.YES, color: '#d4edbc'},
    {label: "👎", presence: IPresence.NO, color: '#ffcfc9'},
    {label: "🤷‍♂️", presence: IPresence.DO_NOT_KNOW, color: '#ffe5a0'}
];

interface IPresenceProps {
    response: IResponse;
}

const Presence = (props: IPresenceProps) => {

    const {response} = props;
    const [presence, setPresence] = useState(response.presence);

    const updatePresence = (updatedPresence: IPresence) => {
        setPresence(updatedPresence);
        response.presence = updatedPresence;
        //updateGraphQLResponse(response);
    }

    return <Box>
        {chips.map(chip =>
            <Chip key={chip.label} label={chip.label} size="small"
                  variant={chip.presence == presence ? "filled" : "outlined"}
                  onClick={() => updatePresence(chip.presence)}
                  sx={{marginRight: '4px', backgroundColor: chip.presence == presence ? chip.color : ""}}/>)
        }
    </Box>
}

export default Presence;