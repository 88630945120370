import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';

import {fanfaronRepository, fetchFanfarons} from "../services/FanfaronService";
import {IFanfaron} from "../models/IFanfaron";

interface IAnnuaireViewProps {
    loadData: boolean;
}

const AnnuaireView = (props: IAnnuaireViewProps) => {

    const [fanfarons, setFanfarons] = useState([] as IFanfaron[]);
    const [oldFanfarons, setOldFanfarons] = useState([] as IFanfaron[]);

    useEffect(() => {
        fetchFanfarons().then(() => {
            setFanfarons(fanfaronRepository.filter(f => !f.old));
            setOldFanfarons(fanfaronRepository.filter(f => f.old));
        });
    }, [props.loadData]);

    return <>
        <FanfaronsTable fanfarons={fanfarons}/>
        <br/>
        <FanfaronsTable fanfarons={oldFanfarons}/>
    </>
}

interface IFanfaronsTable {
    fanfarons: IFanfaron[];
}

const FanfaronsTable = (props: IFanfaronsTable) => {

    return <>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size={'small'}>
                <TableHead style={{height: '50px'}}>
                    <TableRow>
                        <TableCell>Fanfaron</TableCell>
                        <TableCell>Prénom</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Instrument</TableCell>
                        <TableCell>Numéro de téléphone</TableCell>
                        <TableCell>Adresse e-mail</TableCell>
                        <TableCell>Adresse postale</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.fanfarons.map(fanfaron => (
                        <TableRow key={fanfaron.id}
                                  sx={{
                                      '&:last-child td, &:last-child th': {border: 0},
                                      height: '20px',
                                      fontSize: '8px'
                                  }}>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.nickname}</Typography></TableCell>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.firstName}</Typography></TableCell>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.lastName}</Typography></TableCell>
                            <TableCell><Typography
                                fontSize={'11px'}>{fanfaron.musicalInstrument}</Typography></TableCell>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.phoneNumber}</Typography></TableCell>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.emailAddress}</Typography></TableCell>
                            <TableCell><Typography fontSize={'11px'}>{fanfaron.address}</Typography></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default AnnuaireView;