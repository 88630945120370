import {IGraphQLResponse} from "../../../graphql/models/IGraphQLResponse";
import {IPresence, IResponse} from "../../models/IResponse";
import {IFanfaron} from "../../models/IFanfaron";
import {ICar} from "../../models/ICar";
import {fetchGraphQLResponses} from "../../../graphql/GraphQLService";

export let responseRepository: IResponse[] = [];

export const fetchResponses = (): Promise<IResponse[]> => {
    if (responseRepository.length !== 0) return new Promise(resolve => resolve(responseRepository));
    return new Promise(resolve =>
        fetchGraphQLResponses().then((ctis: any) => {
            responseRepository = ctis.map((response: any) => adaptGraphQLResponseToResponse(response));
            resolve(responseRepository);
        }));
}

export const adaptGraphQLResponseToResponse = (graphQLResponse: IGraphQLResponse): IResponse => {
    return {
        id: graphQLResponse.id,
        eventId: graphQLResponse.eventId,
        fanfaronId: graphQLResponse.fanfaronId,
        fanfaron: {} as IFanfaron,
        presence: IPresence[graphQLResponse.presence as keyof typeof IPresence],
        desire: graphQLResponse.desire,
        outwardCarId: graphQLResponse.outwardCarId,
        inwardCarId: graphQLResponse.inwardCarId,
        outwardCar: {} as ICar,
        inwardCar: {} as ICar
    }
}

export const adaptResponseToGraphQLResponse = (response: IResponse): IGraphQLResponse => {
    return {
        id: response.id,
        eventId: response.eventId,
        fanfaronId: response.fanfaronId,
        presence: response.presence,
        desire: response.desire,
        outwardCarId: response.outwardCarId,
        inwardCarId: response.inwardCarId
    }
}

export const fetchResponsesByEventId = (eventId: string): IResponse[] => {
    return responseRepository.filter(response => response.eventId === eventId);
}

export const sortResponsesByFanfaronOrder = (responses: IResponse[]) => {
    return responses.sort((a, b) =>
        a.fanfaron.order > b.fanfaron.order ? 1 : -1);
}
