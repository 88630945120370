import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {IEvent} from "../models/IEvent";
import {eventRepository, fetchEvents} from "../services/EventService";
import EventRow from "../components/calendar/EventRow";
import Buttons from "../components/commons/Buttons";
import CreateEventDialog from "../components/CreateEventDialog";

const EventsView = () => {

    const [events, setEvents] = useState([] as IEvent[]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchEvents().then(() => setEvents(eventRepository));
    }, []);

    const openDialog = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>

        <Buttons buttons={[
            {icon: <AddIcon/>, label: "Créer un événement", onClick: openDialog, display: true}
        ]}/>

        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="Réponses des fanfarons">

                <TableHead style={{height: '50px'}}>
                    <TableRow>
                        <TableCell>Événement</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Lieu</TableCell>
                        <TableCell>Réponses</TableCell>
                        <TableCell>Envie</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {events.map(event => <EventRow key={event.id} event={event}/>)}
                </TableBody>

            </Table>
        </TableContainer>

        <CreateEventDialog open={open} onClose={handleClose}/>
    </>
}

export default EventsView;