import {IGraphQLCar} from "../../../graphql/models/IGraphQLCar";
import {ICar, IJourney} from "../../models/ICar";
import {fetchGraphQLCars} from "../../../graphql/GraphQLService";

export let carRepository: ICar[] = [];

export const fetchCars = (): Promise<ICar[]> => {
    if (carRepository.length !== 0) return new Promise(resolve => resolve(carRepository));
    return new Promise(resolve =>
        fetchGraphQLCars().then((ctis: any) => {
            carRepository = ctis.map((car: any) => adaptGraphQLCarToCar(car));
            resolve(carRepository);
        }));
}

export const adaptGraphQLCarToCar = (graphQLCar: IGraphQLCar): ICar => {
    return {
        id: graphQLCar.id,
        journey: IJourney[graphQLCar.journey as keyof typeof IJourney],
        need: graphQLCar.need,
        available: graphQLCar.available,
        seats: graphQLCar.seats
    }
}

export const adaptCarToGraphQLCar = (car: ICar): IGraphQLCar => {
    return {
        id: car.id,
        journey: car.journey,
        need: car.need,
        available: car.available,
        seats: car.seats
    }
}

export const fetchCarByCarId = (carId: string): ICar => {

    const filteredCars = carRepository.filter(car => car.id === carId);
    return filteredCars.length > 0 ? filteredCars[0] : {} as ICar;
}

