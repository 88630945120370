import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {IEvent} from "../../models/IEvent";
import {sortResponsesByFanfaronOrder} from "../../services/calendar/ResponseService";
import FanfaronResponse from "./FanfaronResponse";


interface IFanfaronResponses {
    event: IEvent
}

const FanfaronResponses = (props: IFanfaronResponses) => {

    const {event} = props;

    return <>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="Réponses des fanfarons">
                <TableHead>
                    <TableRow>
                        <TableCell>Fanfaron</TableCell>
                        <TableCell>Présence</TableCell>
                        <TableCell>Envie</TableCell>
                        <TableCell>Voiture aller</TableCell>
                        <TableCell>Voiture retour</TableCell>
                        <TableCell>Pupitre</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortResponsesByFanfaronOrder(event.responses).map(response => (
                        <FanfaronResponse
                            key={response.id}
                            response={response}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default FanfaronResponses;