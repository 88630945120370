import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Divider, List, ListItemButton, ListItemText, Toolbar} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import {styled} from '@mui/material/styles';
import {eventRepository} from "../../services/EventService";

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 240,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            }),
            boxSizing: 'border-box'
        }
    })
);

interface IMyDrawerProps {
    loadData: boolean;
}

const MyDrawer = (props: IMyDrawerProps) => {

    const [events, setEvents] = useState([] as ILinkMenuProps[]);

    useEffect(() => {
        setEvents(eventRepository.map(event => ({path: '/agenda/' + event.id, name: event.startDate})));
    }, [props.loadData]);

    return <>
        <Drawer variant="permanent" open={true}>

            <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            </Toolbar>

            <Divider/>

            <List component="nav">

                <LinkMenu path={'/administration'} name={'administration'}/>
                <SubLinkMenu path={'/administration/annuaire'} name={'annuaire'}/>

                <LinkMenu path={'/agenda'} name={'agenda'}/>
                <SubLinkMenu path={'agenda/recap'} name={'mon récap'}/>
                {events.map(event => <SubLinkMenu key={event.path} path={event.path} name={event.name}/>)}

            </List>
        </Drawer>
    </>
}

interface ILinkMenuProps {
    path: string;
    name: string;
}

const LinkMenu = (props: ILinkMenuProps) => {

    return <>
        <Link to={props.path} style={{textDecoration: 'none'}}>
            <ListItemButton>
                <ListItemText primary={props.name} sx={{color: '#000000'}}/>
            </ListItemButton>
        </Link>
    </>
}

const SubLinkMenu = (props: ILinkMenuProps) => {

    return <>
        <Link to={props.path} style={{textDecoration: 'none'}}>
            <ListItemButton sx={{paddingLeft: '30px', height: '30px'}}>
                <ListItemText primary={props.name} sx={{color: '#000000'}}/>
            </ListItemButton>
        </Link>
    </>
}

export default MyDrawer;