import {IAuthenticatedUser} from "../models/IUser";
import {getToken} from "./AuthenticationService";

export const getUser = (): IAuthenticatedUser => {

    try {
        const token = getToken();
        const payload = token?.split('.')[1] || '';
        return JSON.parse(atob(payload)) as IAuthenticatedUser;
    } catch (error: any) {
        return {} as IAuthenticatedUser;
    }
}