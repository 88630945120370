import React, {useState} from 'react';
import {Alert, Box, Card, CardContent, CardHeader, Snackbar} from '@mui/material';

import TerminusLogo from "../../assets/images/logo.jpg";
import GoogleAuthentication from "../components/authentication/GoogleAuthentication";

interface IAuthenticationViewProps {
    setIsAuthenticated: any;
}
const AuthenticationView = (props: IAuthenticationViewProps) => {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleError = (response: any) => {
        setMessage(response);
        setOpen(true);
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    }

    return <>
        <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
            <Card>
                <CardHeader title={"Espace admin du Terminus Brass Band"}/>

                <Box sx={{display: 'flex', justifyContent: 'center', height: 200}}>
                    <img src={TerminusLogo} alt="Logo du Terminus Brass Band"
                         style={{height: '100%'}}/>
                </Box>

                <CardContent sx={{display: 'flex', justifyContent: 'center'}}>
                    <GoogleAuthentication
                        onLoginSuccess={() => props.setIsAuthenticated(true)}
                        onLoginError={handleError}/>
                </CardContent>
            </Card>
        </Box>

        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {message}
            </Alert>
        </Snackbar>
    </>
}

export default AuthenticationView;