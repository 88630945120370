import {IFanfaron} from "./IFanfaron";
import {ICar} from "./ICar";

export interface IResponse {
    id: string;
    eventId: string;
    fanfaronId: string;
    fanfaron: IFanfaron;
    presence: IPresence;
    desire: number;
    outwardCarId?: string;
    inwardCarId?: string;
    outwardCar?: ICar;
    inwardCar?: ICar;
}

export enum IPresence {
    NONE = "NONE",
    YES = "YES",
    NO = "NO",
    DO_NOT_KNOW = "DO_NOT_KNOW"
}