import React from 'react';
import {Link} from 'react-router-dom';
import {styled, TableRow} from '@mui/material';
import TableCell from '@mui/material/TableCell';

import {IEvent} from "../../models/IEvent";
import {IPresence, IResponse} from "../../models/IResponse";
import {fanfaronRepository} from "../../services/FanfaronService";


const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IEventRowProps {
    event: IEvent;
}

const EventRow = (props: IEventRowProps) => {

    const {event} = props;

    const startDate = (): string => {

        const date = new Date(event.startDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = (day < 10) ? '0' + day : day;
        const formattedMonth = (month < 10) ? '0' + month : month;

        return formattedDay + '/' + formattedMonth + '/' + year;
    }

    const countYesResponses = (responses: IResponse[]): number =>
        responses.filter(response => response.presence == IPresence.YES).length;

    const countNoResponses = (responses: IResponse[]): number =>
        responses.filter(response => response.presence == IPresence.NO).length;

    const countDoNotKnowResponses = (responses: IResponse[]): number =>
        responses.filter(response => response.presence == IPresence.DO_NOT_KNOW).length;

    const countResponses = (responses: IResponse[]): number =>
        responses.filter(response => response.presence != IPresence.NONE).length;

    const calculateAverageDesire = (responses: IResponse[]): number => {
        const filteredResponses = responses.filter(response => response.desire != 0);
        return filteredResponses.length == 0 ? 0 :
            filteredResponses.reduce((a, b) => a + b.desire, 0) / filteredResponses.length;
    }

    return <>
        <StyledTableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>

            <TableCell>
                <Link to={"/agenda/" + event.id}>{event.name}</Link>
            </TableCell>

            <TableCell>
                {startDate()}
            </TableCell>

            <TableCell>
                {event.location}
            </TableCell>

            <TableCell>
                <span style={{marginRight: '15px'}}>👍{countYesResponses(event.responses)}</span>
                <span style={{marginRight: '15px'}}>👎{countNoResponses(event.responses)}</span>
                <span style={{marginRight: '20px'}}>🤷‍♂️{countDoNotKnowResponses(event.responses)}</span>

                {countResponses(event.responses)}/{fanfaronRepository.filter(f => !f.old).length}
            </TableCell>

            <TableCell>
                {calculateAverageDesire(event.responses).toFixed(2)}
            </TableCell>

        </StyledTableRow>
    </>
}

export default EventRow;