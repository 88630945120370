import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Box, CssBaseline, Toolbar} from '@mui/material';
import '@aws-amplify/ui-react/styles.css';

import MyDrawer from "./app/components/navigation/MyDrawer";
import MyAppBar from "./app/components/navigation/MyAppBar";
import WelcomeView from "./app/views/WelcomeView";
import AnnuaireView from "./app/views/AnnuaireView";
import EventsView from "./app/views/EventsView";
import EventView from "./app/views/EventView";
import EventsByFanfaronView from "./app/views/EventsByFanfaronView";
import AuthenticationView from "./app/views/AuthenticationView";
import {isLoggedIn} from "./app/services/AuthenticationService";

const mdTheme = createTheme({
    typography: {
        fontFamily: `IBMPlexMono, "Roboto", sans-serif`
    }
});

const App = () => {

    const [loadData, setLoadData] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => setIsAuthenticated(isLoggedIn()), []);

    if (!isAuthenticated) return <AuthenticationView setIsAuthenticated={setIsAuthenticated}/>

    return <>
        <BrowserRouter>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{display: 'flex'}}>

                    <CssBaseline/>
                    <MyAppBar setIsAuthenticated={setIsAuthenticated}/>
                    <MyDrawer loadData={loadData}/>

                    <Box component="main" sx={{
                        backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1, height: '100vh', overflow: 'auto', padding: '15px'
                    }}>

                        <Toolbar/>

                        <Routes>
                            <Route path={'/administration'} element={<WelcomeView/>}/>
                            <Route path={'/administration/annuaire'}
                                   element={<AnnuaireView loadData={loadData}/>}/>

                            <Route path={'/agenda'} element={<EventsView/>}/>
                            <Route path={'/agenda/recap'}
                                   element={<EventsByFanfaronView loadData={loadData}
                                                                  username={"vendredi"}/>}/>
                            <Route path={'/agenda/recap/:fanfaronUsername'}
                                   element={<EventsByFanfaronView loadData={loadData}/>}/>
                            <Route path={'/agenda/:eventId'} element={<EventView loadData={loadData}/>}/>
                        </Routes>

                    </Box>
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    </>
}

export default App;
