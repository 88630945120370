import React, {useState} from 'react';
import {Box} from '@mui/material';

import {updateGraphQLCar} from "../../../graphql/GraphQLService";
import {ICar} from "../../models/ICar";

interface ICarProps {
    car: ICar;
}

const Car = (props: ICarProps) => {

    const {car} = props;
    const [need, setNeed] = useState(car.need);
    const [available, setAvailable] = useState(car.available);
    const [seats, setSeats] = useState(car.seats);

    const updateNeed = (updatedNeed: boolean) => {
        setNeed(updatedNeed);
        car.need = updatedNeed;
        updateGraphQLCar(car);
    }

    const updateAvailable = (updatedAvailable: boolean) => {
        setAvailable(updatedAvailable);
        car.available = updatedAvailable;
        updateGraphQLCar(car);
    }

    const updateSeats = (updatedSeats: number) => {
        setSeats(updatedSeats);
        car.seats = updatedSeats;
        updateGraphQLCar(car);
    }

    return <Box>

    </Box>
}

export default Car;