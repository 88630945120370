import React from 'react';
import {GoogleLogin} from '@react-oauth/google';

import {callLogin} from "../../services/ApiService";
import {saveToken} from "../../services/AuthenticationService";

interface IGoogleAuthenticationProps {
    onLoginSuccess: any;
    onLoginError: any;
}

const GoogleAuthentication = (props: IGoogleAuthenticationProps) => {

    const handleSuccess = (response: any) => {
        if (response && response.credential) {
            callLogin(response.credential)
                .then(res => {
                    saveToken(res.token);
                    props.onLoginSuccess(res.token);
                })
                .catch(err => props.onLoginError(err.message));
        } else {
            props.onLoginError(response.error);
        }
    }

    const handleError = () => props.onLoginError("Echec lors de l'authentification");

    return <>
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
        />
    </>
}

export default GoogleAuthentication;