import React, {useState} from 'react';
import {Box} from '@mui/material';

import {IResponse} from "../../models/IResponse";
import {updateGraphQLResponse} from "../../../graphql/GraphQLService";

interface IDesireProps {
    response: IResponse;
}

const Desire = (props: IDesireProps) => {

    const {response} = props;
    const [desire, setDesire] = useState(response.desire);

    const updateDesire = (updatedDesire: number) => {
        setDesire(updatedDesire);
        response.desire = updatedDesire;
        //updateGraphQLResponse(response);
    }

    return <Box>

    </Box>
}

export default Desire;