import React from 'react'
import {Grid} from '@mui/material';

import Button, {IButtonProps} from "./Button";

interface IButtonsProps {
    buttons: IButtonProps[];
}

const Buttons = (props: IButtonsProps) => {

    return <>
        <Grid container justifyContent="flex-end" sx={{marginBottom: '15px'}}>
            {props.buttons.map(button =>
                <Button key={button.label}
                        icon={button.icon}
                        label={button.label}
                        onClick={button.onClick}
                        display={button.display}
                        press={button.press}/>
            )}
        </Grid>
    </>
}

export default Buttons;