import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {IEvent} from "../models/IEvent";
import {fetchEventByEventId} from "../services/EventService";
import FanfaronResponses from "../components/calendar/FanfaronResponses";

interface IEventViewProps {
    loadData: boolean;
}

const EventView = (props: IEventViewProps) => {

    const {eventId} = useParams();
    const [event, setEvent] = useState({} as IEvent);

    useEffect(() => {
        setEvent(fetchEventByEventId(eventId ? eventId : ""));
    }, [eventId, props.loadData]);

    return <>
        {event.id && <FanfaronResponses event={event}/>}
    </>
}

export default EventView;