import {IApiUser} from "../models/api/IApiUser";
import {IApiContract} from "../models/api/IApiContract";
import {getToken} from "./AuthenticationService";

const TERMINUS_API_URL = process.env.REACT_APP_TERMINUS_API_URL
const API_V1 = '/api/v1'

const loginUrl = TERMINUS_API_URL + API_V1 + '/login';
const usersUrl = TERMINUS_API_URL + API_V1 + '/users';
const contractsUrl = TERMINUS_API_URL + API_V1 + '/contracts';

export const callLogin = async (credential: string) => callApi(loginUrl, 'GET', credential);
export const callUsers = async (): Promise<IApiUser[]> => callApi(usersUrl, 'GET', getToken());
export const callContracts = async (): Promise<IApiContract[]> => callApi(contractsUrl, 'GET', getToken());


const callApi = async (url: string, method: string, credential: string) => {

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + credential
    });

    const response = await fetch(url, {
        method: method,
        headers: headers
    });

    const json = await response.json();

    if (!response.ok) throw new Error(json.error.description);
    return json;
}