import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import {IEvent} from "../models/IEvent";
import EventRow from "../components/calendar/EventRow";
import {fetchFanfaronByUsername} from "../services/FanfaronService";
import {IFanfaron} from "../models/IFanfaron";
import {IResponse} from "../models/IResponse";
import {fetchResponses} from "../services/calendar/ResponseService";

interface IEventsViewProps {
    loadData: boolean;
    username?: string;
}

const EventsView = (props: IEventsViewProps) => {

    const {fanfaronUsername} = useParams();
    const [fanfaron, setFanfaron] = useState({} as IFanfaron);
    const [events, setEvents] = useState([] as IEvent[]);
    const [responses, setResponses] = useState([] as IResponse[]);

    useEffect(() => {
        setFanfaron(fetchFanfaronByUsername(props.username ? props.username : fanfaronUsername));
    }, [props.loadData]);

    return <>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="Réponses des fanfarons">

                <TableHead style={{height: '50px'}}>
                    <TableRow>
                        <TableCell/>
                        <TableCell>🎪 Événement</TableCell>
                        <TableCell>📆 Date</TableCell>
                        <TableCell>🚀 Lieu</TableCell>
                        <TableCell>🐤 Réponses</TableCell>
                        <TableCell>🔥 Envie</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {events.map(event => <EventRow key={event.id} event={event}/>)}
                </TableBody>

            </Table>
        </TableContainer>
    </>
}

export default EventsView;