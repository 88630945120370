import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';

import {createEvent} from "../services/calendar/CalendarService";

interface ICreateEventDialogProps {
    open: boolean;
    onClose: any;
}

const CreateEventDialog = (props: ICreateEventDialogProps) => {

    const {open, onClose} = props;

    const create = () => {
        createEvent({
            id: uuidv4(),
            name: "Défilé au lampions",
            startDate: "2023-12-01",
            endDate: "2023-12-01",
            description: "Super défilé",
            responses: []
        });
    }

    return <>
        <Dialog onClose={onClose} open={open} maxWidth={'md'}>

            <DialogTitle>
                TODO
            </DialogTitle>

            <DialogContent>

            </DialogContent>

        </Dialog>
    </>
}

export default CreateEventDialog;