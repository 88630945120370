import {IEvent} from "../models/IEvent";
import {IApiContract, IApiContractAttendee} from "../models/api/IApiContract";
import {IGraphQLEvent} from "../../graphql/models/IGraphQLEvent";
import {callContracts} from "./ApiService";
import {IPresence, IResponse} from "../models/IResponse";
import {IFanfaron} from "../models/IFanfaron";


export let eventRepository: IEvent[] = [];


export const fetchEvents = (): Promise<IEvent[]> => {
    if (eventRepository.length !== 0) return new Promise(resolve => resolve(eventRepository));
    return new Promise(resolve =>
        callContracts().then((contracts: IApiContract[]) => {
            eventRepository = contracts.map((contract: any) => adaptApiContractToEvent(contract));
            resolve(eventRepository);
        }));
}

const adaptApiContractToEvent = (contract: IApiContract): IEvent => {
    return {
        id: contract.id.toString(),
        name: contract.name,
        startDate: contract.startDate.toString(),
        endDate: contract.startDate.toString(),
        description: contract.description,
        location: contract.location,
        responses: contract.contractAttendee.map(contractAttendee => adaptApiContractAttendeeToResponse(contractAttendee))
    }
}

const adaptApiContractAttendeeToResponse = (contractAttendee: IApiContractAttendee): IResponse => {
    return {
        id: contractAttendee.id.toString(),
        eventId: contractAttendee.contractId.toString(),
        fanfaronId: contractAttendee.userId.toString(),
        fanfaron: {} as IFanfaron,
        presence: (contractAttendee.canAttend && contractAttendee.canAttend.toUpperCase() in IPresence) ?
            IPresence[contractAttendee.canAttend.toUpperCase() as keyof typeof IPresence] :
            IPresence.DO_NOT_KNOW,
        desire: contractAttendee.attendWillingnessScore
    }
}

export const adaptEventToGraphQLEvent = (event: IEvent): IGraphQLEvent => {
    return {
        id: event.id,
        name: event.name,
        startDate: event.startDate,
        endDate: event.endDate,
        description: event.description
    }
}

export const fetchEventByEventId = (eventId: string): IEvent => {

    const filteredEvents = eventRepository.filter(event => event.id === eventId);
    return filteredEvents.length > 0 ? filteredEvents[0] : {} as IEvent;
}